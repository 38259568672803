import $ from 'cash-dom';

$(window).on('load', () => {
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener('click', function (e) {
      if (document.querySelector(this.getAttribute('href')).scrollIntoView != undefined) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        });
      }
    });
  });
});
