import $ from 'cash-dom'

// $(document).on('load', () => {
//   $('.faq-item').not('.is-open').addClass('is-closed')
// })

$(document).on('click', '.bof-prices-list-link.is-centered.is-mobile', (e) => {
  e.preventDefault()
  console.log(e);
  const item = $(e.currentTarget.nextElementSibling)
  const icon = $(e.currentTarget.children[0])
  item.toggleClass('is-open')
  icon.toggleClass('fa-chevron-down')
  icon.toggleClass('fa-chevron-up')
})