import $ from 'cash-dom'

function validateEmailSyntax(value) {
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (value.match(validRegex)) {
    return true
  } else {
    return false
  }
}

function validateField(field, type = 'text') {
  let valid = true
  const container = field.closest('.form-field')

  container.removeClass('has-error')
  
  if (type === 'email') {
    if (!validateEmailSyntax(field.val())) {
      valid = false
      container.addClass('has-error')
    }
  } else if (type === 'text') {
    if (!field.val()) {
      valid = false
      container.addClass('has-error')
    }
  } 

  return valid
}

function init() {
  const form = document.getElementById('new_subscriber')
  if (!form) {
    return
  }

  console.log('init', form)

  form.setAttribute('novalidate', '')
}

$(document).on('change', '#subscriber_first_name', (e) => {
  validateField($(e.currentTarget))
})

$(document).on('change', '#subscriber_last_name', (e) => {
  validateField($(e.currentTarget))
})

$(document).on('change', '#subscriber_email', (e) => {
  validateField($(e.currentTarget), 'email')
})

$(document).on('submit', '#new_subscriber', (e) => {
  let valid = true

  const form = $(e.currentTarget)

  if (!validateField(form.find('#subscriber_first_name'))) {
    valid = false
  }

  if (!validateField(form.find('#subscriber_last_name'))) {
    valid = false
  }

  if (!validateField(form.find('#subscriber_email'), 'email')) {
    valid = false
  }

  if (!valid) {
    e.preventDefault()
  }
})

$(window).on('load', init)