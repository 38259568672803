import $ from 'cash-dom'

$(window).on('load', () => {
  $('.faq-item').not('.is-open').addClass('is-closed')
})

$(document).on('click', '.faq-item-toggle', (e) => {
  e.preventDefault()
  const item = $(e.target).closest('.faq-item')
  console.log('item', item.length)
  item.toggleClass('is-closed')
})