import $ from 'cash-dom'

let map = null
let initInterval = null
let counter = 0
let resizeTimeout = null

function includeMapScript() {
  let script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = 'https://api.mapbox.com/mapbox-gl-js/v2.8.1/mapbox-gl.js'
  script.id = 'mapBoxScript'

  document.head.appendChild(script);

  let link = document.createElement('link')
  link.rel = 'stylesheet'
  link.href = 'https://api.mapbox.com/mapbox-gl-js/v2.8.1/mapbox-gl.css'
  link.id = 'mapBoxCss'

  document.head.appendChild(link);
}

function init() {
  if (!document.getElementById('map')) {
    return
  }

  if (typeof mapboxgl !== 'object') {
    includeMapScript()
  }
  
  initInterval = setInterval(() => {

    if (typeof mapboxgl === 'object') {
      clearInterval(initInterval)
      initMap()
      return
    }

    if (counter >= 200)  {
      clearInterval(initInterval)
      return
    }

    counter += counter
  }, 100)
}

function getMapCenter() {
  let center = [14.737861474264719, 56.88061086641582]

  if (window.innerWidth <= 740) {
    center = [14.774696182229604, 56.898563345499355]
  }

  return center
}

function initMap() {
  mapboxgl.accessToken = 'pk.eyJ1IjoiZ2FicmllbC1zdmVubmVyYmVyZyIsImEiOiJjbDNzb2o0ZzgwMTIwM2Nudmoycmw1NDVrIn0.fKWvXdet0y_F2jef_0o5DA'

  map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/gabriel-svennerberg/cl3sonuey008c15msjmezpuz3',
    center: getMapCenter(),
    zoom: 12,
    interactive: false
  })
  
  new mapboxgl.Marker({ 
    color: 'black',
  })
  .setLngLat([14.774147, 56.880127])
  .addTo(map)
}

function setMapCenter() {
  if (!map) {
    return
  }

  if (resizeTimeout) {
    clearTimeout(resizeTimeout)
  }

  resizeTimeout = setTimeout(() => {
    map.setCenter(getMapCenter())
  }, 100)

}

$(window).on('load', init)
$(window).on('resize', setMapCenter)