import Typed from 'typed.js';

function init() {
  window.bof = window.bof || {}
  let strings = window.bof.headings
  
  if (!strings) {
    return
  }

  for (let i = 0; i < strings.length; i++) {
    strings[i] = `^500${strings[i]}`
  }

  if (document.querySelector('.typed')) {
    document.querySelector('.typed').innerHTML = ''

    new Typed('.typed', {
      strings,
      typeSpeed: 50,
      backSpeed: 50,
      loop: true,
    })
  }
}

window.addEventListener("load", init)
