import $ from 'cash-dom'

$(document).on('click', '.accordion-item-toggle', (e) => {
  let item = $(e.currentTarget).closest('.accordion-item')
  
  if (item.hasClass('is-open')) {
    item.removeClass('is-open')
  } else {
    $(e.currentTarget).closest('.accordion').find('.accordion-item').removeClass('is-open')
    item.addClass('is-open')
  }
  
})