import Glide from '@glidejs/glide'
import $ from 'cash-dom'

function init() {
  let elem = document.getElementById('features')
  if (!elem) {
    return
  }

  let toggleTextSpan = $(elem).find('.dropdown-toggle span')

  let glide = new Glide('#features', {
    type: 'carousel',
    perView: 1,
    autoplay: 7000,
  })

  glide.on('run', function(e) {
    let nav = $(elem).find('.selector-nav')
    nav.find('.selector-nav-item').removeClass('is-active')
    nav.find('.selector-nav-item').eq(glide.index).addClass('is-active')

    let dropdownItems = $(elem).find('.dropdown-items')
    let selectedItem = dropdownItems.find('.dropdown-item').eq(glide.index)
    let text = selectedItem.find('span').text()
    dropdownItems.find('.dropdown-item').removeClass('is-selected')
    selectedItem.addClass('is-selected')
    toggleTextSpan.text(text)
  })

  glide.mount()
}

$(window).on('load', init)


