
import $ from 'cash-dom'
import Glide from '@glidejs/glide'

function init() {
  if (!document.getElementById('testimonials')) {
    return
  }
  
  var glide = new Glide('#testimonials', {
    type: 'carousel',
    perView: 1,
    autoplay: 5000,

  })
  
  glide.mount()
}

window.addEventListener("load", init)