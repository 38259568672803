import $ from 'cash-dom'
import ScrollTrigger from '@terwanerik/scrolltrigger'

let locale = 'en-IN'

// How long you want the animation to take, in ms
const animationDuration = 2000;
// Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
const frameDuration = 1000 / 60;
// Use that to calculate how many frames we need to complete the animation
const totalFrames = Math.round( animationDuration / frameDuration );
// An ease-out function that slows the count as it progresses
const easeOutQuad = t => t * ( 2 - t );

// The animation function, which takes an Element
const animateCountUp = el => {
	let frame = 0;
  let value = el.innerHTML
  if (value) {
    value = value.replace(/ /g, '')
  }
	const countTo = parseInt(value, 10)
	// Start the animation running 60 times per second
	const counter = setInterval( () => {
		frame++;
		// Calculate our progress as a value between 0 and 1
		// Pass that value to our easing function to get our
		// progress on a curve
		const progress = easeOutQuad( frame / totalFrames );
		// Use the progress value to calculate the current count
		const currentCount = Math.round( countTo * progress );

		// If the current count has changed, update the element
		if ( parseInt( el.innerHTML, 10 ) !== currentCount ) {
			el.innerHTML = formatNumber(currentCount);
		}

		// If we’ve reached our last frame, stop the animation
		if ( frame === totalFrames ) {
			clearInterval( counter );
		}
	}, frameDuration );
};

// Run the animation on all elements with a class of ‘countup’
function runAnimations() {
	const countupEls = document.querySelectorAll('.stats-item-value')
	countupEls.forEach(animateCountUp);
}

function formatNumber(number) {
  if (typeof Intl !== 'object') {
    return number
  }
  return Intl.NumberFormat(locale).format(number)
}

function isMobileDevice() {
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  } else{
    return false
  }
}

function init() {

  if (document.documentElement.lang === 'sv') {
    locale = 'sv-SE'
  }

  const trigger = new ScrollTrigger()

  if (!isMobileDevice()) {
    trigger.add(document.querySelectorAll('.stats-item-value'), {
      once: true,
      toggle: {
        callback: {
          in: (el) => {
            runAnimations()
          },
        }
      }
    })
  }
  
  // trigger.add(document.querySelectorAll('.packages'), {
  //   once: true,
  //   toggle: {
  //     callback: {
  //       in: (el) => {
  //         console.log(el)
  //         $(el.element).addClass('is-visible')
  //       },
  //     }
  //   }
  // })

}

$(window).on('load', init)